import { ErrorTracker } from '../utils/errorTracker';

const Civet = new ErrorTracker();

window.civet = Civet;

window.addEventListener('error', (event) => {
	const { error } = event;
	Civet.reportError(error);
});

window.addEventListener('unhandledrejection', (error) => {
	Civet.reportError(error.reason);
});

export { Civet };
